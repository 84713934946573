.wrapper-center {
  display: flex;
  justify-content: center;
  align-items: center;
 
}
.form {
  width: 300px;
  margin-top: 150px;
  h1 {
    text-align: center;
  }
  .btn {
    margin: 0 auto;
  }
}

.buttons-wrapper {
  display: flex;
  justify-content: space-around;
}